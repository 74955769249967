import React, { useEffect, useState } from 'react';
import 'uikit/dist/css/uikit.min.css';
import 'uikit/dist/js/uikit.min.js';
import './sliderInput.css';

const SliderInput = ({ 
    label, 
    min, 
    max, 
    unit, 
    decimal, 
    value, 
    onChange, 
    id, 
    name, 
    code,
    p 
}) => {
    // The predefined set of values to snap to when p is true
    const snapValues = [26.5, 29.5, 34.5, 37.5, 42.5, 50.5, 62.5, 72.5, 82.5, 92.5];

    // Function to find the nearest value from snapValues
    const getNearestSnapValue = (val) => {
        return snapValues.reduce((prev, curr) => 
            Math.abs(curr - val) < Math.abs(prev - val) ? curr : prev
        );
    };

    // Internal slider value to be displayed (with decimals if needed)
    const [sliderValue, setSliderValue] = useState(parseFloat(value));
    const [numberInputValue, setNumberInputValue] = useState(parseFloat(value)); // Separate state for number input

    // Convert the parent value into a decimal for slider display if p is true
    useEffect(() => {
        if (p && value && (value > max)) {
            const decimalValue = parseFloat(value) / 10;
            setSliderValue(decimalValue);
            setNumberInputValue(decimalValue);
        } else {
            setSliderValue(parseFloat(value));
            setNumberInputValue(parseFloat(value));
        }
    }, [value, p]);

    // Function to handle slider change
    const handleSliderChange = (e) => {
        let newValue = decimal ? parseFloat(e.target.value) : Math.floor(e.target.value);

        // If 'p' is true, snap the value to the nearest predefined value
        if (p) {
            newValue = getNearestSnapValue(newValue);
        }

        setSliderValue(newValue); // Update the internal slider value
        setNumberInputValue(newValue); // Sync number input value with slider value
    };

    // Function to format the value for output based on p
    const formatValueForOutput = (value) => {
        if (p) {
            if (String(value).includes('.')){
                return String(value).replace('.', '');
            }
            else {
                return String(value).concat('0');
            }
        }
        return value;
    };

    // Function to handle when the slider is released or onBlur is triggered
    const handleSliderRelease = () => {
        const outputValue = formatValueForOutput(sliderValue);
        const finalValue = code ? `${code}^${outputValue}` : outputValue;
        onChange({ target: { name, value: finalValue } }); // Notify parent
    };

    // Function to handle number input change (without updating slider value immediately)
    const handleNumberInputChange = (e) => {
        setNumberInputValue(e.target.value);
    };

    const handleBlur = () => {
        // Ensure the value remains within bounds
        let newValue = parseFloat(numberInputValue);
        if (newValue < min) newValue = min;
        if (newValue > max) newValue = max;

        // If 'p' is true, snap the value to the nearest predefined value
        if (p) {
            newValue = getNearestSnapValue(newValue);
        }

        const outputValue = formatValueForOutput(newValue);
        const finalValue = code ? `${code}^${outputValue}` : outputValue;
        onChange({ target: { name, value: finalValue } });
        setSliderValue(newValue); // Sync slider value with the updated number input value
        setNumberInputValue(newValue); // Update number input to match the final value
    };

    return (
        <div className="slider-container">
            <div className="uk-child-width-1-1 uk-child-width-1-3@m uk-flex-middle uk-grid uk-grid-small uk-margin-medium-top uk-margin-medium-bottom">
                <div>
                    <label className="uk-form-label uk-display-block">
                        {label}:
                    </label>
                </div>
                <div className="uk-width-2-3@m">
                    <div className="slider-wrapper">
                        <div className="slider-input-wrapper">
                            <input
                                id={id}
                                type="range"
                                min={min}
                                max={max}
                                step={decimal ? 0.1 : 1}
                                value={sliderValue} // Bind to internal state for slider
                                onChange={handleSliderChange} // Only update slider value on change
                                onMouseUp={handleSliderRelease} // Handle when slider is released
                                onTouchEnd={handleSliderRelease} // Handle touch release
                                className="slider"
                            />
                            <div className='slider-labels'>
                                <span>{min}</span>
                                <span>{max}</span>
                            </div>
                        </div>
                        
                        <div className="slider-value-input">
                            <input
                                type="number"
                                step={decimal ? 0.1 : 1}
                                value={numberInputValue} // Bind to number input state
                                onChange={handleNumberInputChange} // Update number input value only
                                onBlur={handleBlur} // Validate and update on blur
                                className="uk-input"
                                style={{ marginLeft: '10px', textAlign: 'right' }}
                            />
                            <span className='uk-text-meta' style={{ paddingLeft: '10px', float: 'right' }}>{unit}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SliderInput;