import React, { useState } from 'react';
import axios from 'axios';

const EmailQuote = ({ formData, productUrl }) => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleEmailSubmit = async () => {
        setIsSubmitting(true);
        formData.MakePDF = 1;
        console.log(formData);

        try {
            // Fetch uValue and downloadUrl from your existing API
            const response = await fetch('/api/getuvalue', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData),
            });

            const data = await response.json();
            console.log(data);
            const { uvalue, downloadUrl } = data;

            // Send email using WordPress REST API
            await axios.post('https://uvalue-calculator.nimbus.sugarprojects.com/wp-json/email/v1/send-quote', {
                email,
                uValue: uvalue,
                url: downloadUrl, // Download URL
                productUrl, // Include the productUrl prop here
                data,
                formData
            });

            formData.MakePDF = 0;

            setMessage('U-Value has been sent successfully!');
        } catch (error) {
            setMessage('There was an error sending the U-Value. Please try again.');
            console.error('Error:', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div>
            <div className="uk-margin-top uk-flex uk-width-1-1">
                <input
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="uk-input"
                    disabled={isSubmitting}
                    style={{ width: '75%' }}
                />
                <button
                    className="uk-button uk-button-primary"
                    type="button"
                    onClick={handleEmailSubmit}
                    disabled={isSubmitting || !email}
                    style={{ width: '25%' }}
                >
                    {isSubmitting ? 'Sending...' : 'Send U-Value'}
                </button>
            </div>
            {message && <p>{message}</p>}
        </div>
    );
};

export default EmailQuote;