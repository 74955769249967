import React, { useState, useEffect, useRef } from 'react';
import SliderInput from './sliderInput'; 
import TextInput from './textInput'; 
import SelectInput from './SelectInput'; 
import DimensionsInput from './DimensionsInput';
import EmailQuote from './EmailQuote';

// Utility function to parse URL query parameters
const getUrlParams = (search) => {
    return search
        .substring(1)  // Remove "?" at the start
        .split('&')     // Split into key=value pairs
        .reduce((params, param) => {
            const [key, value] = param.split('=');   // Split key and value
            params[key] = decodeURIComponent(value); // Decode and assign to params object
            return params;
        }, {});
};

const ProductVariables = ({ product, setResult, result }) => {
    const initialFormData = {
        Valid: false,
        ProjectId: "0",
        GuidId: "",
        AllInsulationsEC: "",
        CeilingCB: 0,
        DeckCB: 0,
        Element: 1,
        FloorJoistWidthEC: 0,
        FloorJoistDepthEC: 0,
        FloorJoistCentresEC: 0,
        InnerFinishCB: 0,
        InnerLeafCB: 0,
        InnerLeafThicknessEC: 0,
        InsulationLayer1CB: "",
        InsulationLayer2CB: "",
        InsulationLayer3CB: "",
        MakePDF: 0,
        MembraneFixingsCB: 0,
        mmSlabThicknessEC: 0,
        OuterFinishCB: 0,
        OuterLeafCB: 0,
        OuterLeafThicknessEC: 0,
        POverAEC: 0.1,
        ProjectInfoString: "",
        RafterDepthCB: 0,
        RafterSpacingCB: 0,
        RafterWidthCB: 0,
        StudDepthCB: 0,
        TargetUvalueEC: 0,
        WallCavityDepthmmEC: 0,
        WaterproofMembraneCB: 0,
        MakePDF: 0,
    };

    const [formData, setFormData] = useState(initialFormData);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitDisabledV, setIsSubmitDisabledV] = useState(true);
    const [validationComplete, setValidationComplete] = useState(false);
    // Set insulation codes dynamically based on product settings
    const insulationCode = product.acf.insulation_type;

    const [productUrl, setProductUrl] = useState('');

    useEffect(() => {
        const updateFormData = () => {
            const params = getUrlParams(window.location.search);
            let updatedFormData = { ...initialFormData };  // Start with initial form data
    
            // Merge URL parameters with the formData, but skip params with a value of '0'
            Object.keys(params).forEach(key => {
                if (key in updatedFormData && params[key] !== '0') {
                    updatedFormData[key] = decodeURIComponent(params[key]);  // Assign values from URL params if not '0'
                }
            });
    
            // Update formData when the product changes
            if (product && product.acf && product.acf.element_id) {
                updatedFormData.Element = product.acf.element_id; // Add product-specific data
            }
    
            // Set insulation layers based on the URL or product settings
            if (insulationCode) {
                // Handle InsulationLayer1CB
                updatedFormData.InsulationLayer1CB = (params.InsulationLayer1CB && params.InsulationLayer1CB !== '0')
                    ? decodeURIComponent(params.InsulationLayer1CB) // Use URL value if available and not '0'
                    : product.acf.enable_insulation_thickness
                        ? `${insulationCode}^${(insulationCode === "QL" || insulationCode === "QLFoil") 
                            ? 26.5 
                            : (insulationCode === "QF" 
                                ? 20 
                                : 25)}`
                        : product.acf.enable_insulation_over_rafters
                        ? `${insulationCode}^20`
                        : product.acf.enable_insulation_under_rafters
                        ? `${insulationCode}^265`
                        : product.acf.enable_insulation_above_joists
                        ? `${insulationCode}^20`
                        : product.acf.enable_insulation_under_joists
                        ? `${insulationCode}^20`
                        : product.acf.enable_mannok_therm_cavity_insulation
                        ? `QW^25`
                        : product.acf.enable_insulation_within_frame
                        ? `${insulationCode}^25`
                        : product.acf.enable_cavity_insulation
                        ? `${insulationCode}^25`
                        : updatedFormData.InsulationLayer1CB;  // If none of the conditions match, retain the current value
    
                // Handle InsulationLayer2CB
                updatedFormData.InsulationLayer2CB = (params.InsulationLayer2CB && params.InsulationLayer2CB !== '0')
                    ? decodeURIComponent(params.InsulationLayer2CB) // Use URL value if available and not '0'
                    : product.acf.enable_insulation_between_rafters
                        ? `QR^20`
                        : product.acf.enable_insulation_between_studs
                        ? `${insulationCode}^25`
                        : product.acf.enable_laminate_kraft_internal_insulation
                        ? `QL^265`
                        : product.acf.enable_insulation_between_joists
                        ? `${insulationCode}^20`
                        : updatedFormData.InsulationLayer2CB;  // If none of the conditions match, retain the current value
            }
    
            setFormData(prevFormData => ({
                ...prevFormData, // Preserve previous formData values
                ...updatedFormData,  // Merge with new updates
            }));
        };
    
        updateFormData();  // Trigger the function when URL or product changes
    
    }, [product, insulationCode]);  // Depend on product and insulation code
    


    // Create a ref to track the latest isSubmitDisabledV value
    const isSubmitDisabledRef = useRef(isSubmitDisabledV);

    // Synchronize ref with the latest value of isSubmitDisabledV
    useEffect(() => {
        isSubmitDisabledRef.current = isSubmitDisabledV;
    }, [isSubmitDisabledV]);

    // Reset formData when the product changes, but merge with current state to avoid overwriting URL-based values
    useEffect(() => {
        if (product && product.acf && product.acf.element_id) {
            setFormData((prevFormData) => ({
                ...prevFormData,  // Keep any previously set data (e.g., from URL)
                Element: product.acf.element_id,  // Only update specific fields
            }));
        }
    }, [product]);

    useEffect(() => {
        console.log("formData: ", formData);
    }, [formData]);

    useEffect(() => {
        const validateForm = () => {
            if (product.acf.enable_internal_leaf && (formData.InnerLeafCB === 0 || formData.InnerLeafCB === "0")) {
                setIsSubmitDisabledV(true);
                return;
            }
            if (product.acf.enable_internal_finish && (formData.InnerFinishCB === 0 || formData.InnerFinishCB === "0")) {
                setIsSubmitDisabledV(true);
                return;
            }
            if (product.acf.enable_waterproof_membrane && (formData.WaterproofMembraneCB === 0 || formData.WaterproofMembraneCB === "0")) {
                setIsSubmitDisabledV(true);
                return;
            }
            if (product.acf.enable_rafter_dimensions) {
                if (formData.RafterDepthCB === "" || formData.RafterWidthCB === "" || formData.RafterSpacingCB === 0 || formData.RafterSpacingCB === "0") {
                    setIsSubmitDisabledV(true);
                    return;
                }
            }
            if (product.acf.enable_joist_dimensions) {
                if (formData.FloorJoistDepthEC === "" || formData.FloorJoistWidthEC === "" || formData.FloorJoistCentresEC === 0 || formData.FloorJoistCentresEC === "0") {
                    setIsSubmitDisabledV(true);
                    return;
                }
            }
            if(isSubmitDisabledV){
                setIsSubmitDisabledV(false); // Form is valid
            }
        };

        validateForm();
        setValidationComplete(true); // Mark validation as complete
    }, [formData]);

    // Handle form submission logic
    const submitForm = async () => {

        if (isSubmitDisabledRef.current || product.enquiry_only) {
            setResult({ uvalue: '0.00' });
            return;
        }

        setIsSubmitting(true);
        setResult({ uvalue: 'Calculating...' });

        try {
            const response = await fetch('/api/getuvalue', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData),
            });
            const data = await response.json();
            setResult(data);
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    // Only trigger form submission when validation is complete and the form is valid
    useEffect(() => {
        if (validationComplete && !isSubmitDisabledV) {
            submitForm();
            setValidationComplete(false)
        }
    }, [isSubmitDisabledV, validationComplete]);

    // Log when the submit button is disabled or enabled
    useEffect(() => {
        if (isSubmitDisabledV) {
            setResult({ uvalue: '0.00' });
        }
    }, [isSubmitDisabledV]);

    // Generate the URL when formData changes
    useEffect(() => {
        if (product && product.id) {
            setProductUrl(generateProductUrl(product, formData));
        }
    }, [formData]);


    const generateProductUrl = (product, formData) => {
        // Base URL for your app
        const baseUrl = "https://react-uvalue.nimbus.sugarprojects.com/";
    
        // Create URL with the current product ID
        let Url = `${baseUrl}?product=${product.id}`;
    
        // Loop through formData and add each field as a query parameter
        Object.keys(formData).forEach(key => {
            // Ensure that only defined and non-empty values are added to the URL
            if (formData[key] !== undefined && formData[key] !== "") {
                Url += `&${key}=${encodeURIComponent(formData[key])}`;
            }
        });
    
        // Log the generated URL to the console
        console.log("Generated URL:", Url);
    
        // Return the URL as a string
        return Url;
    };
    
    const hasUserInteracted = useRef(false);

    const resetFormAndClearUrl = (product) => {
        // Get the base URL (without any query parameters)
        const baseUrl = window.location.origin + window.location.pathname;
    
        // Update the URL (with only 'product' parameter or an empty query string)
        window.history.pushState({}, "", baseUrl);
    };
    
    // Reset formData when the product changes but avoid triggering on initial load
    useEffect(() => {
        if (product && product.id) {
            if (hasUserInteracted.current) {
                resetFormAndClearUrl(product);
            }
        }
    }, [product]);

    // Mark that the user has interacted with any input or action on the page
    const markUserInteraction = () => {
        hasUserInteracted.current = true; // Track user interaction
    };

    // Track user interactions globally
    useEffect(() => {
        // Attach event listeners for common user interaction events
        const handleUserInteraction = () => {
            hasUserInteracted.current = true; // Mark that the user has interacted
        };

        // Listen for interactions anywhere on the page
        window.addEventListener('click', handleUserInteraction);  // For clicks
        window.addEventListener('keydown', handleUserInteraction); // For keyboard input
        window.addEventListener('input', handleUserInteraction);  // For any form input

        return () => {
            // Cleanup event listeners on unmount
            window.removeEventListener('click', handleUserInteraction);
            window.removeEventListener('keydown', handleUserInteraction);
            window.removeEventListener('input', handleUserInteraction);
        };
    }, []);

    // Example: Handle change in input fields
    const handleChange = (e) => {
        markUserInteraction(); // Mark user interaction on input change
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    

    if(product.acf.enquiry_only){
        return (
            <div className='uk-margin-medium-top'>
                <p>The selected product calculation is not yet available for online calculation. The calculation for this product can be obtained by contacting our Technical by Mannok team.</p>

                <ul className="uk-list">
                    <li>Tel: <a data-v-c8feaa2e="" href="tel:08000322122">08000 322 122 (UK/NI)</a></li>
                    <li>Tel: <a data-v-c8feaa2e="" href="tel:1800322122">1800 322 122 (ROI)</a></li>
                    <li>Email: <a data-v-c8feaa2e="" href="mailto:technical@mannokbuild.com">technical@mannokbuild.com</a></li>
                </ul>
            </div>
        )
    }
    else{
        return (
            <div>
                {/* PA Ratio Slider */}
                {product.acf.enable_pa_ratio && (
                    <SliderInput 
                        label="PA Ratio" 
                        min={0.1} 
                        max={1} 
                        unit="" 
                        decimal={true} 
                        name="POverAEC" 
                        value={formData.POverAEC} 
                        onChange={handleChange} 
                        id={`pa-ratio-slider`}
                    />
                )}

                {/* Slab Thickness Input */}
                {product.acf.enable_slab_thickness && (
                    <TextInput 
                        label="Slab Thickness" 
                        name="mmSlabThicknessEC" 
                        value={formData.mmSlabThicknessEC} 
                        onChange={handleChange}
                        unit={"mm"}
                    />
                )}

                {/* Cavity Depth Input */}
                {product.acf.enable_cavity_depth && (
                    <TextInput 
                        label="Cavity Width" 
                        name="WallCavityDepthmmEC" 
                        value={formData.WallCavityDepthmmEC} 
                        onChange={handleChange}
                        unit={"mm"}
                    />
                )}

                {/* Internal Leaf Select Input */}
                {product.acf.enable_internal_leaf && (
                    <div>
                        <SelectInput 
                            label="Internal Leaf Type" 
                            name="InnerLeafCB" 
                            value={formData.InnerLeafCB} 
                            onChange={handleChange}
                            values={{
                                "Blockwork (dense) - 1.33W/mK": 1,
                                "Blockwork (medium dense) - 0.30W/mK": 3,
                                "Blockwork (medium dense) - 0.45W/mK": 2,
                                "Quinn Lite B3 - 0.12W/mK": 6,
                                "Quinn Lite B5 - 0.17W/mK": 5,
                                "Quinn Lite B7 - 0.19W/mK": 4
                            }}
                        />
                    </div>
                )}

                {/* Internal Leaf Select Input */}
                {product.acf.enable_internal_leaf_thickness && (
                    <div>
                        <TextInput 
                            label="Internal Leaf Thickness" 
                            name="InnerLeafThicknessEC" 
                            value={formData.InnerLeafThicknessEC} 
                            onChange={handleChange}
                            unit={"mm"}
                        />
                    </div>
                )}

                {/* Internal Finish Select Input */}
                {product.acf.enable_internal_finish && (
                    <SelectInput 
                        label="Internal Finish" 
                        name="InnerFinishCB" 
                        value={formData.InnerFinishCB} 
                        onChange={handleChange}
                        values={{
                            "Plaster": 1,
                            "Plasterboard on dabs": 2,
                            "Plasterboard": 3
                        }}
                    />
                )}

                {/* Joist Dimensions Input */}
                {product.acf.enable_joist_dimensions && (
                    <DimensionsInput 
                        label="Joist Dimensions"
                        placeholders={['Width', 'Depth']}
                        unit="mm"
                        isRafter={false} // False for joists
                        values={{
                            FloorJoistWidthEC: formData.FloorJoistWidthEC,
                            FloorJoistDepthEC: formData.FloorJoistDepthEC,
                            FloorJoistCentresEC: formData.FloorJoistCentresEC
                        }}
                        onChange={handleChange}
                    />
                )}

                {/* Rafter Dimensions Input */}
                {product.acf.enable_rafter_dimensions && (
                    <DimensionsInput 
                        label="Rafter Dimensions"
                        placeholders={['Width', 'Depth']}
                        unit="mm"
                        isRafter={true} // True for rafters
                        values={{
                            RafterWidthCB: formData.RafterWidthCB,
                            RafterDepthCB: formData.RafterDepthCB,
                            RafterSpacingCB: formData.RafterSpacingCB
                        }}
                        onChange={handleChange}
                    />
                )}

                {/* Waterproof Membrane Select Input */}
                {product.acf.enable_waterproof_membrane && (
                    <SelectInput 
                        label="Waterproof Membrane" 
                        name="WaterproofMembraneCB" 
                        value={formData.WaterproofMembraneCB} 
                        onChange={handleChange} 
                        values={{
                            "Built-up roofing (BS6229)": 1,
                            "Single ply membrane": 2
                        }}
                    />
                )}

                {/* Stud Depth Input */}
                {product.acf.enable_stud_depth && (
                    <TextInput 
                        label="Stud Depth" 
                        name="StudDepthCB" 
                        value={formData.StudDepthCB} 
                        onChange={handleChange}
                        unit={"mm"}
                    />
                )}

                {/* Insulation Thickness Slider */}
                {product.acf.enable_insulation_thickness && (
                    <SliderInput 
                        label="Insulation Thickness" 
                        // Set min and max based on the insulationCode value
                        min={
                            insulationCode === "QL" || insulationCode === "QLFoil" 
                                ? 26.5 
                                : insulationCode === "QF" 
                                ? 20 
                                : 25
                        } 
                        max={
                            insulationCode === "QL" || insulationCode === "QLFoil" 
                                ? 92.5 
                                : 150
                        }
                        unit="mm" 
                        decimal={insulationCode === "QL" || insulationCode === "QLFoil"} 
                        p={insulationCode === "QL" || insulationCode === "QLFoil"}
                        name="InsulationLayer1CB" 
                        value={Number((formData.InsulationLayer1CB || '0^0').split('^')[1]) || 0} // Ensure value is a number
                        code={insulationCode}
                        onChange={handleChange} 
                        id={`insulation-thickness-slider`} 
                    />
                )}


                {/* Insulation Over Rafters Slider */}
                {product.acf.enable_insulation_over_rafters && (
                    <SliderInput 
                        label="Insulation Over Rafters" 
                        min={20} 
                        max={150} 
                        unit="mm" 
                        decimal={false} 
                        name="InsulationLayer1CB" 
                        value={Number((formData.InsulationLayer1CB || '0^0').split('^')[1]) || 0} // Ensure value is a number
                        code={insulationCode}
                        onChange={handleChange} 
                        id={`insulation-over-rafters-slider`} 
                    />
                )}

                {/* Insulation Under Rafters Slider */}
                {product.acf.enable_insulation_under_rafters && (
                    <SliderInput 
                        label="Insulation Under Rafters" 
                        min={26.5} 
                        max={92.5} 
                        unit="mm" 
                        decimal={true} 
                        name="InsulationLayer1CB" 
                        value={Number((formData.InsulationLayer1CB || '0^265').split('^')[1]) || 0} // Ensure value is a number
                        code={insulationCode}
                        onChange={handleChange} 
                        id={`insulation-under-rafters-slider`}
                        p={true}
                    />
                )}

                {/* Insulation Above Joists Slider */}
                {product.acf.enable_insulation_above_joists && (
                    <SliderInput 
                        label="Insulation Above Joists" 
                        min={20} 
                        max={150} 
                        unit="mm" 
                        decimal={false} 
                        name="InsulationLayer1CB" 
                        value={Number((formData.InsulationLayer1CB || '0^2').split('^')[1]) || 0} // Ensure value is a number
                        code={insulationCode}
                        onChange={handleChange} 
                        id={`insulation-above-joists-slider`} 
                    />
                )}

                {/* Insulation Under Joists Slider */}
                {product.acf.enable_insulation_under_joists && (
                    <SliderInput 
                        label="Insulation Under Joists" 
                        min={20} 
                        max={150} 
                        unit="mm" 
                        decimal={false} 
                        name="InsulationLayer1CB" 
                        value={Number((formData.InsulationLayer1CB || '0^0').split('^')[1]) || 0} // Ensure value is a number
                        code={insulationCode}
                        onChange={handleChange} 
                        id={`insulation-under-joists-slider`} 
                    />
                )}

                {/* Mannok Therm Cavity Insulation Slider */}
                {product.acf.enable_mannok_therm_cavity_insulation && (
                    <SliderInput 
                        label="Mannok Therm Cavity Insulation" 
                        min={25} 
                        max={150} 
                        unit="mm" 
                        decimal={false} 
                        name="InsulationLayer1CB" 
                        value={Number((formData.InsulationLayer1CB || '0^0').split('^')[1]) || 0} // Ensure value is a number
                        code={insulationCode}
                        onChange={handleChange} 
                        id={`mannok-therm-cavity-insulation-slider`} 
                    />
                )}

                {/* Insulation Within Frame Slider */}
                {product.acf.enable_insulation_within_frame && (
                    <SliderInput 
                        label="Insulation Within Frame" 
                        min={25} 
                        max={150} 
                        unit="mm" 
                        decimal={false} 
                        name="InsulationLayer1CB" 
                        value={Number((formData.InsulationLayer1CB || '0^25').split('^')[1]) || 0} // Ensure value is a number
                        code={insulationCode}
                        onChange={handleChange} 
                        id={`insulation-within-frame-slider`} 
                    />
                )}

                {/* Cavity Insulation Slider */}
                {product.acf.enable_cavity_insulation && (
                    <SliderInput 
                        label="Cavity Insulation" 
                        min={25} 
                        max={110} 
                        unit="mm" 
                        decimal={false} 
                        name="InsulationLayer1CB" 
                        value={Number((formData.InsulationLayer1CB || '0^25').split('^')[1]) || 0} // Ensure value is a number
                        code={insulationCode}
                        onChange={handleChange} 
                        id={`cavity-insulation-slider`} 
                    />
                )}

                {/* Insulation Between Rafters Slider */}
                {product.acf.enable_insulation_between_rafters && (
                    <SliderInput 
                        label="Insulation Between Rafters" 
                        min={20} 
                        max={150} 
                        unit="mm" 
                        decimal={false} 
                        name="InsulationLayer2CB" 
                        value={Number((formData.InsulationLayer2CB || '0^20').split('^')[1]) || 0} // Ensure value is a number
                        code={'QR'}
                        onChange={handleChange} 
                        id={`insulation-between-rafters-slider`}
                    />
                )}

                {/* Insulation Between Studs Slider */}
                {product.acf.enable_insulation_between_studs && (
                    <SliderInput 
                        label="Insulation Between Studs" 
                        min={25} 
                        max={150} 
                        unit="mm" 
                        decimal={false} 
                        name="InsulationLayer2CB" 
                        value={Number((formData.InsulationLayer2CB || '0^25').split('^')[1]) || 0} // Ensure value is a number
                        code={insulationCode}
                        onChange={handleChange} 
                        id={`insulation-between-studs-slider`} 
                    />
                )}

                {/* Laminate Kraft Internal Insulation Slider */}
                {product.acf.enable_laminate_kraft_internal_insulation && (
                    <SliderInput 
                        label="Laminate Kraft Internal Insulation" 
                        min={26.5} 
                        max={92.5} 
                        unit="mm" 
                        decimal={true}
                        name="InsulationLayer2CB" 
                        value={Number((formData.InsulationLayer2CB || '0^26.5').split('^')[1]) || 26.5} // Ensure value is a number
                        code={'QL'}
                        onChange={handleChange} 
                        id={`laminate-kraft-internal-insulation-slider`} 
                        p={true}
                    />
                )}

                {/* Insulation Between Joists Slider */}
                {product.acf.enable_insulation_between_joists && (
                    <SliderInput 
                        label="Insulation Between Joists" 
                        min={20} 
                        max={150} 
                        unit="mm" 
                        decimal={false} 
                        name="InsulationLayer2CB" 
                        value={Number((formData.InsulationLayer2CB || '0^20').split('^')[1]) || 0} // Ensure value is a number
                        code={insulationCode}
                        onChange={handleChange} 
                        id={`insulation-between-joists-slider`} 
                    />
                )}



                {/* If #UValueDisplay.value != 0.00 */}
                {/* show email input and send uvalue button */}
                {/* Conditionally show email input and send button based on UValue */}
                {result && result.uvalue !== '0.00' && (
                    <div>
                        <EmailQuote formData={formData} productUrl={productUrl}/>
                    </div>
                )}
            </div>
        );
    }
};

export default ProductVariables;