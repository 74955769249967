import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './ProductDisplay.css';

const ProductDisplay = ({ product, result }) => {
    const [imageUrl, setImageUrl] = useState(null);
    
    // URL of the logo image
    const logoUrl = "https://uvalue-calculator.nimbus.sugarprojects.com/wp-content/uploads/2024/09/Mannok_Logo.png";

    useEffect(() => {
        if (product && product.featured_media) {
            // Fetch the featured media (image)
            axios
                .get(`https://uvalue-calculator.nimbus.sugarprojects.com/wp-json/wp/v2/media/${product.featured_media}`)
                .then(response => {
                    setImageUrl(response.data.source_url); // Set the image URL
                })
                .catch(err => {
                    console.error('Error fetching the featured image:', err);
                });
        }
        else{
            setImageUrl(null); // Set the image URL
        }
    }, [product]);

    const displayUValue = () => {
        if (result && result.uvalue !== null) {
            if (typeof result.uvalue === 'number') {
                return result.uvalue.toFixed(2); // Format to 2 decimal places
            } else {
                return result.uvalue; // Return as is if it's not a number
            }
        }
        return "0.00"; // Default display if uvalue is null
    };

    return (
        <div>
            {/* Render the logo and U-Value section (always visible) */}
            <div style={{ textAlign: 'center', margin: '20px 0' }}>
                <img 
                    src={logoUrl} 
                    alt="Mannok Logo" 
                    style={{ maxWidth: '100%', height: 'auto' }} 
                />
            </div>
    
            {/* Using a conditional operator to render U-Value */}
            {displayUValue() !== '0.00' ? (
                <>
                    <div className='uk-align-center uk-text-center uk-h3 uk-margin-remove'>U-Value</div>
                    <div id='UValueDisplay' className='uk-align-center uk-text-center uk-h1 uk-margin-remove'>{displayUValue()}</div>
                    <div className='uk-align-center uk-text-center uk-text-meta uk-margin-remove'>W/m²K</div>

                    <div className={`sticky-footer ${displayUValue() !== '0.00' ? 'show' : ''}`}>
                        <div style={{ fontSize: '26px', color: 'hsla(0, 0%, 100%, .5)' }}>U-Value</div>
                        <div style={{ fontSize: '34px' }}>{displayUValue()}</div>
                        <div style={{ fontSize: '12px', color: 'hsla(0, 0%, 100%, .5)' }}>W/m²K</div>
                    </div>


                </>
            ) : null}

            
    
            {/* Conditionally render product-specific content */}
            {product ? (
                <>
                    {/* Display product image if available */}
                    {imageUrl ? (
                        <div>
                            <img 
                                src={imageUrl} 
                                alt={product.title.rendered} 
                                style={{ maxWidth: 'auto', height: '250px', margin: '20px auto', display: 'block' }}
                            />
                        </div>
                    ) : (
                        <p className='uk-text-meta' style={{ maxWidth: 'auto', height: '250px', margin: '20px auto', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                            No image available for this product
                        </p>
                    )}
    
                    {/* Display product title and description */}
                    <p className='uk-h4'>{((product.title?.rendered || 'Unnamed Product').replace(/&#8211;/g, '-')).replace(/&amp;/g, '&')}</p>
                    <div style={{ fontSize: ".8em" }} dangerouslySetInnerHTML={{ __html: product.acf?.description }} />
                    {/*<pre>{JSON.stringify(result, null, 2)}</pre>*/}
                </>
            ) : null}
        </div>
    );
};    

export default ProductDisplay;

