import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import ProductSelect from './ProductSelect';
import './UvalueCalculator.css';
import ProductDisplay from './ProductDisplay';
import SelectProductInput from './SelectProductInput';
import TechSupport from './TechSupport';
import GeneralInfo from './GeneralInfo';

const WordPressPosts = () => {
    const [posts, setPosts] = useState([]);
    const [error, setError] = useState(null);
    const [categories, setCategories] = useState([]);
    const [selectedParentCategory, setSelectedParentCategory] = useState(null);
    const [selectedSubCategory, setSelectedSubCategory] = useState(null);
    const [categoryForProductSelect, setCategoryForProductSelect] = useState(null);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [result, setResult] = useState(null);
    const [selectedSubCategorySlug, setSelectedSubCategorySlug] = useState(''); // For slugs
    const [loadingCategories, setLoadingCategories] = useState(true);
    const [loadingProducts, setLoadingProducts] = useState(true);

    const location = useLocation();
    const navigate = useNavigate();

    const fetchCategoryNames = async (categoryIds) => {
        try {
            const categoryRequests = categoryIds.map(id =>
                axios.get(`https://uvalue-calculator.nimbus.sugarprojects.com/wp-json/wp/v2/categories/${id}`)
            );
            const categoryResponses = await Promise.all(categoryRequests);
            const categoryData = categoryResponses.map(res => res.data);
            
            const reversedCategoryData = categoryData.reverse();
            setCategories(reversedCategoryData);
            setLoadingCategories(false);
        } catch (err) {
            console.error('Error fetching categories:', err);
        }
    };

    useEffect(() => {
        axios.get('https://uvalue-calculator.nimbus.sugarprojects.com/wp-json/wp/v2/products?per_page=100')
            .then(response => {
                setPosts(response.data);
                setError(null);
                setLoadingProducts(false);

                const allCategories = response.data.flatMap(post => post.categories || []);
                const uniqueCategories = [...new Set(allCategories)];
                fetchCategoryNames(uniqueCategories);
            })
            .catch(err => {
                console.error('Error fetching posts:', err);
                setError('Could not fetch posts.');
                setLoadingProducts(false);
            });
    }, [location.search]);

    useEffect(() => {
        if (!loadingProducts && !loadingCategories) {
            const params = new URLSearchParams(location.search);
            const productId = params.get('product');
            if (productId) {
                const product = posts.find(p => p.id === parseInt(productId));
                if (product) {
                    initializeProductSelection(product);
                }
            }
        }
    }, [loadingProducts, loadingCategories, posts, categories, location.search]);

    const parentCategories = categories.filter(cat => cat.parent === 0);
    const subCategories = categories.filter(cat => cat.parent === selectedParentCategory);

    const handleParentCategorySelect = (categoryId) => {
        if (categoryId !== selectedParentCategory) {
            setSelectedParentCategory(categoryId);
            setSelectedSubCategory(null);
            setSelectedSubCategorySlug(''); // Reset slug when parent changes
            setCategoryForProductSelect(null);
            setSelectedProduct(null);
            setResult(null);

            //const params = new URLSearchParams(location.search);
            //params.delete('product');
            //navigate({ search: params.toString() }, { replace: true });
        }
    };

    const handleSubCategorySelect = (categoryId, slug) => {
        if (categoryId !== selectedSubCategory) {
            setSelectedSubCategory(categoryId);
            setSelectedSubCategorySlug(slug); // Set slug when subcategory is selected
            setCategoryForProductSelect(categoryId);
            setSelectedProduct(null);
            setResult(null);

            //const params = new URLSearchParams(location.search);
            //params.delete('product');
            //navigate({ search: params.toString() }, { replace: true });
        }
    };

    useEffect(() => {
        if (selectedParentCategory && (subCategories.length === 0 || selectedSubCategory)) {
            setCategoryForProductSelect(selectedSubCategory || selectedParentCategory);
        }
    }, [selectedParentCategory, subCategories, selectedSubCategory]);

    useEffect(() => {
        if (selectedParentCategory && subCategories.length === 0) {
            setCategoryForProductSelect(selectedParentCategory);
        }
    }, [selectedParentCategory, subCategories]);

    const initializeProductSelection = (product) => {
        // Avoid resetting state if the product is already selected
        if (selectedProduct && selectedProduct.id === product.id) return;
    
        const productCategories = product.categories;
    
        if (productCategories && productCategories.length > 0) {
            const parentCat = categories.find(cat =>
                productCategories.includes(cat.id) && cat.parent === 0
            );
    
            if (parentCat) {
                setSelectedParentCategory(parentCat.id);
            }
    
            const subCat = categories.find(cat =>
                productCategories.includes(cat.id) && cat.parent === parentCat?.id
            );
    
            if (subCat) {
                setSelectedSubCategory(subCat.id);
                setSelectedSubCategorySlug(subCat.slug); // Set the slug
            } else {
                setSelectedSubCategory(null);
                setSelectedSubCategorySlug('');
            }
    
            setCategoryForProductSelect(subCat ? subCat.id : parentCat?.id);
            setSelectedProduct(product);
        }
    };    

    useEffect(() => {
        if (selectedProduct) {
            //const params = new URLSearchParams(location.search);
            //params.set('product', selectedProduct.id);
            //navigate({ search: params.toString() }, { replace: true });
        }
    }, [selectedProduct, location.search, navigate]);

    return (
        <section>
            <div className='uk-container uk-margin-large-top uk-margin-large-bottom'>
                <div className='uk-grid'>
                    <div className='uk-width-2-3@m uk-margin-large-top'>
                        <h1>U-Value Calculator</h1>
                        {error && <p style={{ color: 'red' }}>{error}</p>}

                        <div className='uk-margin-large-top'>
                            <div className="uk-child-width-1-1 uk-child-width-1-3@m uk-flex-middle uk-grid uk-grid-small uk-margin-small-bottom">
                                <div>
                                    <label className="uk-form-label uk-display-block">
                                        Construction Type:
                                    </label>
                                </div>
                                <div className="uk-width-2-3@m">
                                    <div className="cat-buttons uk-flex uk-flex-middle uk-flex-between">
                                        {parentCategories.map(category => (
                                            <button
                                                key={category.id}
                                                onClick={() => handleParentCategorySelect(category.id)}
                                                className={`uk-button uk-button-primary ${selectedParentCategory === category.id ? 'uk-active' : ''}`}
                                            >
                                                {category.name}
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {selectedParentCategory && (() => {
                            return (
                                <div>
                                    {/*console.log("selectedSubCategorySlug: ", selectedSubCategorySlug)}
                                    {console.log("subCategories: ", subCategories)}
                                    {console.log("findIndex:", subCategories.findIndex(subCat => subCat.slug === selectedSubCategorySlug))*/}
                                    {subCategories.length > 0 ? (
                                        <SelectProductInput
                                            label="Construction Type Layer 2"
                                            values={subCategories.map(subCat => subCat.name)}
                                            selectedIndex={subCategories.findIndex(subCat => subCat.slug === selectedSubCategorySlug)}
                                            onChange={e => {
                                                const selectedIndex = e.target.selectedIndex;
                                                if (selectedIndex > 0) {
                                                    const selectedSubCat = subCategories[selectedIndex - 1];
                                                    handleSubCategorySelect(selectedSubCat.id, selectedSubCat.slug);
                                                } else {
                                                    setSelectedSubCategory(null);
                                                    setCategoryForProductSelect(null);
                                                    setSelectedProduct(null);
                                                    setResult(null);
                                                }
                                            }}
                                        />
                                    ) : null}
                                </div>
                            );
                        })()}

                        {categoryForProductSelect && (
                            <ProductSelect 
                                category={categoryForProductSelect} 
                                onProductSelect={setSelectedProduct} 
                                setResult={setResult}
                                result={result}
                                product={selectedProduct}
                                setSelectedProduct={setSelectedProduct}
                            />
                        )}
                        <p className='uk-text-meta uk-margin-medium-top'>
                            Whilst the information and/or specification contained here is to the best of our knowledge true and accurate, we specifically exclude any liability for errors, omissions or otherwise arising therefrom. Details, practices, principles, values and calculations should be verified as to accuracy and suitability for the required purpose for use.
                        </p>
                    </div>

                    <div className='uk-width-1-3@m'>
                        <ProductDisplay product={selectedProduct} result={result} />
                    </div>
                </div>
            </div>

            <TechSupport />
            <GeneralInfo />
        </section>
    );
};

export default WordPressPosts;