import React from 'react';
import 'uikit/dist/css/uikit.min.css';
import 'uikit/dist/js/uikit.min.js';

const DimensionsInput = ({ label, placeholders, unit, isRafter, values, onChange }) => {
    // Handle input changes and pass them back to the parent form
    const handleInputChange = (e) => {
        const { id, value } = e.target;
        onChange({ target: { name: id, value: value } });
    };

    return (
        <div className="uk-child-width-1-1 uk-child-width-1-3@m uk-flex-middle uk-grid uk-grid-small uk-margin-small-bottom">
            {/* Label for the entire set of inputs */}
            <div className="uk-first-column">
                <label className="uk-form-label uk-display-block">
                    {label}:
                </label>
            </div>

            {/* Input fields with units */}
            <div className="uk-width-2-3@m">
                <div className="uk-grid uk-grid-small">
                    {/* Input for width */}
                    <div className="uk-width-1-3@s">
                        <div className="uk-flex-middle uk-grid uk-grid-collapse">
                            <div className='uk-text-meta'>Width:</div>
                            <div className="uk-width-expand">
                                <input
                                    id={`${isRafter ? 'RafterWidthCB' : 'FloorJoistWidthEC'}`}
                                    value={`${isRafter ? values.RafterWidthCB : values.FloorJoistWidthEC}`}
                                    type="number"
                                    min={0}
                                    onChange={handleInputChange}
                                    className="uk-input"
                                />
                            </div>
                            <div className="uk-width-auto uk-text-meta">{unit}</div>
                        </div>
                    </div>

                    {/* Input for depth */}
                    <div className="uk-width-1-3@s">
                        <div className="uk-flex-middle uk-grid uk-grid-collapse">
                            <div className='uk-text-meta'>Depth:</div>
                            <div className="uk-width-expand">
                                <input
                                    id={`${isRafter ? 'RafterDepthCB' : 'FloorJoistDepthEC'}`}
                                    value={`${isRafter ? values.RafterDepthCB : values.FloorJoistDepthEC}`}
                                    type="number"
                                    min={0}
                                    onChange={handleInputChange}
                                    className="uk-input uk-margin"
                                />
                            </div>
                            <div className="uk-width-auto uk-text-meta">{unit}</div>
                        </div>
                    </div>

                    {/* Conditional dropdown for spacing or centres */}
                    <div className="uk-width-expand@s">
                        {isRafter ? (
                            <select
                                id="RafterSpacingCB"
                                value={values.RafterSpacingCB}
                                onChange={handleInputChange}
                                className="uk-select uk-width-1-1"
                            >
                                <option value="0">-- Spacing --</option>
                                <option value="400">400mm</option>
                                <option value="450">450mm</option>
                                <option value="600">600mm</option>
                            </select>
                        ) : (
                            <select
                                id="FloorJoistCentresEC"
                                value={values.FloorJoistCentresEC}
                                onChange={handleInputChange}
                                className="uk-select uk-width-1-1"
                            >
                                <option value="0">-- Centres --</option>
                                <option value="400">400mm</option>
                                <option value="600">600mm</option>
                            </select>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DimensionsInput;