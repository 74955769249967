import React, { useEffect, useState } from 'react';
import axios from 'axios';
import SelectProductInput from './SelectProductInput';
import ProductVariables from './ProductVariables';

const ProductSelect = ({ category, onProductSelect, setResult, result, product, setSelectedProduct }) => {
    const [products, setProducts] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState('');

    // Fetch products when category changes
    useEffect(() => {
        if (category) {
            axios.get(`https://uvalue-calculator.nimbus.sugarprojects.com/wp-json/wp/v2/products?categories=${category}`)
                .then(response => {
                    const fetchedProducts = response.data;
                    setProducts(fetchedProducts);

                    // Only set selected product if it differs from the current one
                    if (product) {
                        const matchedProduct = fetchedProducts.find(p => p.id === product.id);
                        if (matchedProduct) {
                            const index = fetchedProducts.findIndex(p => p.id === matchedProduct.id);
                            setSelectedIndex(index);  // Set the correct index
                        }
                    } else {
                        // If product is null, reset selectedIndex to 0
                        setSelectedIndex('');
                    }
                })
                .catch(err => {
                    console.error('Error fetching products:', err);
                });
        }
    }, [category, product]);

    const productTitles = products.map((product) => ({
        label: ((product.title?.rendered || 'Unnamed Product').replace(/&#8211;/g, '-')).replace(/&amp;/g, '&'),
        id: product.id,  // Store product ID
    }));

    // Update selectedIndex when the product changes
    useEffect(() => {
        if (product) {
            const index = productTitles.findIndex(prod => prod.id === product.id);
            if (index !== selectedIndex) {  // Only update if the index has changed
                setSelectedIndex(index);
            }
        } else {
            // Reset index to 0 if the product is null
            setSelectedIndex('');
        }
    }, [product, productTitles, selectedIndex]);

    const handleProductSelect = (productId) => {
        const selected = products.find(p => p.id === productId);
        setResult(null);
        setSelectedProduct(selected);  // Update the parent state
        onProductSelect(selected);  // Notify parent component
    };

    return (
        <div>
            <SelectProductInput 
                label="Insulation Solution" 
                values={productTitles.map(product => product.label)} 
                selectedIndex={selectedIndex}  // Pass the index to SelectProductInput
                onChange={(e) => {
                    const productId = productTitles[e.target.value]?.id;
                    setSelectedIndex(e.target.value);  // Update selectedIndex
                    handleProductSelect(productId);  // Update selected product
                }}
            />

            {product && (
                <ProductVariables product={product} setResult={setResult} result={result} />
            )}
        </div>
    );
};

export default ProductSelect;